export default class RatesApiClient {
  constructor (http) {
    this.http = http
  }

  /**
   * Get a list of property rates from the API
   *
   * @param {(string|number)} propertyId
   * @returns {PropertyRate[]}
   */
  async getPropertyRates (propertyId) {
    const result = await this.http.get(`/rates/${propertyId}`)
    return result.data.data
  }

  /**
   * Create a new property rate
   *
   * @param {(string|number)} id
   * @param {object} data
   * @returns {Object}
   */
  async createPropertyRate (propertyId, data) {
    const result = await this.http.post(`/rates?property_id=${propertyId}`, data)
    return result.data
  }

  /**
   * update a property rate
   *
   * @param {(string|number)} id
   * @param {object} data
   * @returns {Object}
   */
  async updateRate (id, propertyId, data) {
    const result = await this.http.patch(`/rates/${id}?property_id=${propertyId}`, data)
    return result.data
  }

  /**
   * delete a property rate
   *
   * @param {(string|number)} id
   * @param {(string|number)} propertyId
   */
  async deleteRate (id, propertyId) {
    const result = await this.http.delete(`/rates/${id}?property_id=${propertyId}`)
    return result.data.data
  }
}
