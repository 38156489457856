import { MouseEvent } from 'react'
import { LoadingIcon } from 'components/icons'
import classNames from 'classnames'
import { ReactChild } from 'data/types/types'
import Link from 'components/Link'
import { LinkProps } from 'next/link'

export interface ButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'dangerouslySetInnerHTML' | 'style' | 'children' | 'className'> {
  isLoading?: boolean
  style?: 'default' | 'modal' | 'pill' | 'mini' | 'small' | 'round'
  variant?: 'primary' | 'secondary' | 'transparent' | 'underline' | 'grey' | 'dark-grey' | 'tab' | 'sso_login' | 'white-outline'
  size?: 'sm' | 'md'
  children: ReactChild
  className?: {
    button?: string
    text?: string
    loading?: string
    icon?: {
      container?: string
      icon?: string
      suffix?: string
    }
  }
  type?: 'button' | 'submit'
  block?: boolean
  href?: LinkProps['href']
  target?: '_blank' | '_self'
  icon?: ReactChild
  subtext?: string
  suffixIcon?: ReactChild
}

const Button = ({
  children,
  isLoading = false,
  variant = 'primary',
  style = 'default',
  size = 'md',
  className,
  type = 'button',
  block = false,
  disabled = false,
  href,
  target,
  icon,
  subtext,
  suffixIcon,
  ...props
}: ButtonProps) => {
  const styleClasses = classNames(
    // Variant Styles
    {
      'border border-primary bg-primary hover:bg-primary-dark hover:border-primary-dark text-white ring-primary ring-opacity-30': ['primary', 'breakdown'].includes(variant),

      'border border-grey-300 hover:bg-primary-fresh hover:border-primary-fresh hover:text-white ring-primary ring-opacity-30': variant === 'secondary' || variant === 'transparent',
      'bg-white': variant === 'secondary',
      'bg-transparent': variant === 'transparent',
      'border border-grey-50 text-grey-50': variant === 'white-outline',

      'border border-grey-300 hover:border-primary-fresh text-grey-650 hover:text-grey-800 ring-primary ring-opacity-30': variant === 'sso_login',
      'bg-transparent text-grey-800 hover:bg-grey-100 underline ring-grey-800 ring-opacity-10 font-medium': variant === 'underline',
      'bg-grey-100 text-grey-800 hover:brightness-[97%] hover:ring-grey-800 hover:ring-opacity-10 ring-grey-150': variant === 'grey',
      'bg-white text-grey-800 hover:bg-grey-800 hover:text-white hover:ring-grey-800 ring-grey-800': variant === 'dark-grey',
    },
    // General Styles
    style === 'pill'
      ? 'text-12 tracking-3/5 uppercase px-24 leading-14'
      : {
        '!mx-10 !px-0 !normal-case pt-[22px] !pb-10 !tracking-1/4 !text-13 !leading-22 font-bold rounded-none': variant === 'tab',
        'font-bold tracking-3/4 px-24 uppercase leading-24': variant !== 'underline',
        'text-13': variant !== 'underline' && size === 'sm',
        'text-[14px]': variant !== 'underline' && size === 'md',
        'font-poppins tracking-1/4 px-24 text-12 leading-22': variant === 'underline',
      },
    // Loading Styles
    style === 'pill'
      ? {
        'py-[9px]': !isLoading,
        'py-[7px]': isLoading,
      }
      : (
        variant !== 'underline'
          ? (
            {
              'py-[9px]': isLoading && size === 'sm',
              'py-15': !isLoading && size === 'md',
              'py-12': (isLoading && size === 'md') || (!isLoading && size === 'sm'),
            }
          )
          : (
            {
              'py-4': !isLoading,
              'py-[1px]': isLoading,
            }
          )
      ),
    'flex items-center justify-center focus:outline-none',
    {
      'rounded-full !text-12 normal-case font-poppins font-medium px-[16px] py-[4px] leading-22 !tracking-1/4': style === 'round',
      'rounded-16 lg:rounded-4 focus:ring-[3px]': style === 'default',
      'rounded-16 focus:ring-[3px]': style === 'modal',
      'rounded-[17px] focus:ring-[2px]': style === 'pill',
      'rounded-10 !text-11 !px-14 !py-2 normal-case font-poppins !leading-22 !font-medium !h-max': style === 'mini',
      'rounded-8 !text-13 !leading-22 !py-8 !font-bold !capitalize !tracking-[0.25px]': style === 'small',
    },
    className?.button,
    {
      'w-full': block,
    },
    // Disabled Styles
    {
      'opacity-50 pointer-events-none': disabled,
    },
  )

  const renderClassnames = () => {
    return classNames(
      styleClasses,
      {
        'flex-col group': subtext,
      },
    )
  }

  const renderSubContent = () => {
    return subtext
      ? (
        <p className="mt-10 font-normal normal-case text-grey-400 text-15 group-hover:text-white tracking-default">
          {subtext}
        </p>
      )
      : null
  }

  const renderContent = () => {
    return isLoading
      ? (
        <LoadingIcon className={classNames(
          {
            'w-30 h-30': style !== 'pill',
            'w-18 h-18': style === 'pill',
          },
          className?.loading,
        )} />
      )
      : (
        (icon || suffixIcon)
          ? (
            <div className={classNames(
              className?.icon?.container,
              {
                'flex items-center gap-12': icon || suffixIcon,
              },
            )}>
              {
                icon && (
                  <div className={classNames('text-16', className?.icon?.icon)}>{icon}</div>
                )
              }
              {children}
              {
                suffixIcon && (
                  <div className={classNames('text-16', className?.icon?.suffix)}>{suffixIcon}</div>
                )
              }
            </div>
          )
          : children
      )
  }

  if (href) {
    return (
      <Link
        href={href}
        target={target}
        className={renderClassnames()}
        onClick={(e) => props.onClick?.(e as MouseEvent<HTMLButtonElement>)}
      >
        {renderContent()}
        {renderSubContent()}
      </Link>
    )
  }

  return (
    <button
      disabled={disabled || isLoading}
      type={type}
      className={renderClassnames()}
      {...props}
      onMouseEnter={(e) => props.onMouseEnter?.(e)}
      onMouseLeave={(e) => props.onMouseLeave?.(e)}
    >
      {renderContent()}
      {renderSubContent()}
    </button >
  )
}

export default Button

export { Label } from './Label'
export { StickyFooter } from './StickyFooter'
