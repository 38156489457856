import { CommissionApplication } from 'data/enums/trip-schedule/commission-application'
import { CommissionType } from 'data/enums/trip-schedule/commission-type'
import { Status } from 'data/enums/payment-status'
import { ServicePartnerPrice } from 'data/enums/trip-schedule/service-partner-price'
import { TripScheduleGroup } from 'data/types/bookings/trip_schedule'
import {
  UseTripScheduleQuery,
  CommissionType as GraphQlCommissionType,
  CommissionApplication as GraphQlCommissionApplication,
  ServicePartnerPrice as GraphQlServicePartnerPrice,
  TripScheduleServiceStatusType,
} from 'gql/graphql'
import { paysServiceFee } from '@ama-selections/ui'
import { config } from 'data/config'

const isCheckInOrOut = (serviceTitle?: string | null) => {
  return typeof serviceTitle === 'string'
    ? serviceTitle.toLowerCase() === 'check in' || serviceTitle.toLowerCase() === 'check out'
    : false
}

const calculateServiceSubtotal = (tripGroup: TripScheduleGroup) => {
  return calculateServiceSubtotalIndividual({
    serviceFee: tripGroup?.service_fee,
    serviceFeeApplication: tripGroup?.service_fee_application,
    days: tripGroup?.days?.length ?? 0,
  })
}

const hasPayments = (tripGroup: TripScheduleGroup) => {
  return tripGroup?.payment_statuses?.some((status) => status === Status.DUE || status === Status.PENDING)
}

const hasDuePayments = (tripGroup: TripScheduleGroup) => {
  return tripGroup?.payment_statuses?.some((status) => status === Status.DUE)
}

const hasPendingPayments = (tripGroup: TripScheduleGroup) => {
  return tripGroup?.payment_statuses?.some((status) => status === Status.PENDING)
}

const hasCompletedPayments = (tripGroup: TripScheduleGroup) => {
  return tripGroup?.payment_statuses?.some((status) => status === Status.COMPLETED)
}

type CommissionInput = {
  commissionType?: TripScheduleGroup['commission_type'] | GraphQlCommissionType
  partnerPrice?: TripScheduleGroup['partner_price']
  commissionAmount?: TripScheduleGroup['commission_amount']
}

type SubtotalInput = {
  serviceFee: TripScheduleGroup['service_fee']
  serviceFeeApplication: TripScheduleGroup['service_fee_application'] | GraphQlCommissionApplication
  days: number
}

const calculateServiceSubtotalIndividual = (input: SubtotalInput) => {
  let price = input.serviceFee ?? 0

  if (input.serviceFeeApplication?.toLowerCase() === CommissionApplication.EACHDAY) {
    price = price * input.days
  }

  return price
}

// Calculates the commission that AMA gets from the partner.
const calculateCommissionFromPartner = (input: CommissionInput) => {
  if (input.commissionType?.toLowerCase() === CommissionType.PERCENTAGE) {
    return (input.partnerPrice ?? 0) * ((input.commissionAmount ?? 0) / 100)
  } else {
    return (input.commissionAmount ?? 0) * 100
  }
}

// Calculates the total price that the partner will see.
const calculatePartnerTotal = (
  input: CommissionInput,
  commissionFromPartnerIncluded: TripScheduleGroup['commission_included'] | GraphQlServicePartnerPrice,
) => {
  const partnerPrice = input.partnerPrice ?? 0

  if (commissionFromPartnerIncluded?.toLowerCase() === ServicePartnerPrice.INCLUDED) {
    const partnerCommission = calculateCommissionFromPartner(input)

    // If the commission is included, then the partner price includes AMA's commission
    return partnerPrice - partnerCommission
  }

  // If the commission is excluded, then the partner price is the total price that AMA pay to the partner
  return partnerPrice
}

const calculatePartnerPriceToClient = (
  input: CommissionInput,
  commissionFromPartnerIncluded: TripScheduleGroup['commission_included'] | GraphQlServicePartnerPrice,
) => {
  const partnerPrice = input.partnerPrice ?? 0

  // If the commission is not included in the partner price, add the partner price and the commission
  if (commissionFromPartnerIncluded?.toLowerCase() === ServicePartnerPrice.EXCLUDED) {
    const partnerCommission = calculateCommissionFromPartner(input)

    return partnerPrice + partnerCommission
  }

  // If the commission is included in the partner price, the partner price is the price the client pays
  return partnerPrice
}

const calculateCommissionIndividual = ({
  commissionInput,
  subtotalInput,
  membershipType,
}: {
  commissionInput: Omit<CommissionInput, 'respectIncluded'>
  subtotalInput: SubtotalInput
  membershipType: string | undefined | null
}) => {
  return calculateCommissionFromPartner(commissionInput)
    + (paysServiceFee(membershipType)
      ? calculateServiceSubtotalIndividual(subtotalInput)
      : 0)
}

const getPendingPaymentTripGroupsPrice = (tripScheduleGroups: UseTripScheduleQuery['bookingTripScheduleGroups']) => {
  return tripScheduleGroups
    .filter((group) => !isCheckInOrOut(group?.title) && (group.status === TripScheduleServiceStatusType.PendingPayment))
    .reduce((prev, service) => {
      let price = 0

      if (service.is_price_generated === false) {
        price = service.price ?? 0
      } else {
        price = calculateTripServicePrice(
          service.commission_amount,
          service.commission_type,
          service.partner_price,
          service.commission_included,
          service.service_fee,
          service.service_fee_application,
          (service.days ?? []).length,
          false,
          service.booking.client.membership_type,
        )
      }

      return prev + price - (service.payment_totals?.completed ?? 0)
    }, 0)
}

const calculateTripServicePrice = (
  commissionAmount: number | undefined | null,
  commissionType: CommissionType | GraphQlCommissionType | undefined | null,
  partnerPrice: number | undefined | null,
  commissionIncluded: ServicePartnerPrice | GraphQlServicePartnerPrice | undefined | null,
  serviceFee: number | undefined | null,
  serviceFeeApplication: CommissionApplication | GraphQlCommissionApplication | undefined | null,
  numberOfDays: number,
  withStripeFees: boolean | undefined | null,
  membershipType: string | undefined | null,
) => {
  const partnerSubtotal = calculatePartnerPriceToClient(
    {
      commissionAmount,
      commissionType,
      partnerPrice,
    },
    commissionIncluded ?? null,
  )

  const serviceSubtotal = calculateServiceSubtotalIndividual({
    serviceFee: serviceFee ?? null,
    serviceFeeApplication: serviceFeeApplication ?? null,
    days: numberOfDays,
  })

  let price = partnerSubtotal + getCustomerServiceFee(serviceSubtotal, membershipType)

  if (withStripeFees) {
    price = price / (1 - (parseFloat(config.stripePercentageCharge) / 100))
  }

  return Math.ceil(price)
}

const getCustomerServiceFee = (
  customerServiceFee: number | null,
  membershipType: string | undefined | null,
) => {
  if (customerServiceFee === null) {
    return 0
  }

  if (paysServiceFee(membershipType)) {
    return customerServiceFee
  }

  return 0
}

export {
  isCheckInOrOut,
  calculateServiceSubtotal,
  // Payments Page
  hasPayments,
  hasDuePayments,
  hasPendingPayments,
  hasCompletedPayments,
  // Trip Service Modal
  calculateServiceSubtotalIndividual,
  calculateCommissionIndividual,
  calculatePartnerTotal,
  calculatePartnerPriceToClient,
  calculateCommissionFromPartner,
  calculateTripServicePrice,
  // Message Templates
  getPendingPaymentTripGroupsPrice,
  // Commission
  getCustomerServiceFee,
}
