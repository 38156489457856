import { forwardRef, useCallback } from 'react'
import Input from 'components/form/Input'
import { CurrencyType } from 'gql/graphql'
import { getCurrencySign } from 'data/helpers/currency'

interface PriceProps {
  className?: string
  onChange?: (e: number | null) => void
  error?: string
  name?: string
  label?: string
  value?: number | string | null
  placeholder?: string
  defaultValue?: string
  step?: number
  style?: 'admin' | 'customer'
  capitalise?: boolean
  withRing?: boolean
  disabled?: boolean
  readOnly?: boolean
  currency?: CurrencyType
}

const Price = forwardRef<HTMLInputElement, PriceProps>(function price ({
  className = '',
  onChange,
  value,
  step = 0.01,
  style = 'admin',
  capitalise = false,
  withRing = true,
  disabled = false,
  readOnly = false,
  currency,
  defaultValue,
  placeholder,
  label,
  name,
  error,
}, ref) {
  const getText = useCallback((callbackValue?: number | string | null) => {
    if (callbackValue === undefined || callbackValue === null) { return '' }

    let newValue = callbackValue

    if (typeof newValue === 'string') {
      newValue = parseInt(newValue)

      if (isNaN(newValue)) { return '' }
    }

    return (newValue / 100)
  }, [value])

  return (
    <div className={`w-full ${className.split(' ').filter(c => c.match(/^!?(w-|h-)/)).join(' ')}`}>
      <Input
        ref={ref}
        type="number"
        className={className}
        onChange={(e) => onChange && onChange?.(e.target.value === ''
          ? null
          : Math.round(parseFloat(e.target.value) * 100),
        )}
        suffix={getCurrencySign(currency)}
        value={getText(value)}
        step={step}
        style={style}
        capitalise={capitalise}
        withRing={withRing}
        disabled={disabled}
        readOnly={readOnly}
        defaultValue={defaultValue}
        placeholder={placeholder}
        label={label}
        name={name}
        error={error}
      />
    </div>
  )
})

export default Price
