import { joinBy, pluralise } from '@ama-selections/ui'
import { TripScheduleService } from 'data/types/bookings/trip_schedule'
import { getStartTime } from 'data/helpers/survey/tripSchedule'
import { SurveySkiInstructorInfo } from 'gql/graphql'

const getSkiInstructorServiceDetails = (service: TripScheduleService) => {
  const serviceDetails = service.service_details ?? service.group?.service_details

  if (serviceDetails?.includes('$generate_details') && service.survey?.__typename === 'SurveySkiInstructorInfo') {
    const survey = service.survey as SurveySkiInstructorInfo

    const line1 = `(${getStartTime(service.start_time, true)})`

    let line2
    if (survey?.preferred_time) {
      line2 = survey.preferred_time
    }

    let line3
    if (survey?.adults) {
      const adults = pluralise(survey?.adults, 'adult', 's', '', true)
      const children = survey?.children
        ? pluralise(survey?.children, 'child', 'ren', '', true)
        : null

      line3 = joinBy(
        [
          adults,
          children,
        ],
        ' + ',
      )
    }

    return serviceDetails.replaceAll(
      '$generate_details',
      joinBy(
        [
          line1,
          line2,
          line3,
        ].filter(item => !!item),
        '\n',
      ),
    )
  }

  return serviceDetails
}

export { getSkiInstructorServiceDetails }
