import { serialize } from 'object-to-formdata'

export default class UserApiClient {
  constructor (http) {
    this.http = http
  }

  /**
   * Get the details of the current user from the API
   *
   * @returns {Object}
   */
  async getCurrentUser () {
    const result = await this.http.get('/users/current')
    return result.data.data
  }

  /**
   * Get a list of users from the API
   *
   * @param {Object} filters Filters object
   * @param {Array} filters.types User types to filter by
   * @param {number=} filters.status The status to filter by
   * @param {string=} filters.query The query string to search for
   *
   * @param {Object}  options Options object
   * @param {number=}  [options.page = 1] Pagination page
   * @param {number=}  options.limit Pagination limit
   * @param {boolean} options.paginated Whether to return paginated results
   * @returns {{users: Array, meta: Object}}
   */
  async getUsers (filters = {}, options = {}, sortable = {}) {
    const result = await this.http.get('/users', {
      params: {
        ...filters,
        page: options.page ?? 1,
        per_page: options.per_page,
        paginated: options.paginated,
        limit: options.limit,
        sort_direction: sortable?.direction,
        sort_by: sortable?.sort_by,
      },
    })
    return {
      users: result.data.data,
      meta: result.data.meta ?? null,
    }
  }

  /**
   * Create a new user
   *
   * @param {Object} details The details of the new user
   * @returns {Object} The full object of the newly created user
   */
  async createUser (details = {}) {
    const result = await this.http.post('/users', serialize(details))
    return result.data.data
  }

  /**
   * Update an existing user
   *
   * @param {number} id The id of the user to update
   * @param {Object} details The new details of the user
   * @returns Object The full object of the updated user
   */
  async updateUser (id, details = {}) {
    const result = await this.http.patch(`/users/${id}`, serialize(details))
    return result.data.data
  }

  /**
   * Delete a user
   *
   * @param {number} id user ID
   */
  async deleteUser (id) {
    await this.http.delete(`/users/${id}`)
  }

  async createPartnerOwner (details) {
    const result = await this.http.post('/users/partner_owner', serialize(details, { indices: true }))
    return result.data.data
  }

  /**
   * Update an existing partner_owner
   *
   * @param {number} id The id of the partner_owner to update
   * @param {Object} details The new details of the partner_owner
   * @returns Object The full object of the updated partner_owner
   */
  async updatePartnerOwner (id, details) {
    const result = await this.http.patch(`/users/partner_owner/${id}`, serialize(details, { indices: true }))
    return result.data.data
  }

  /**
   * A partner/owner has inquired through typeform
   */
  async partnerInquiry (params) {
    const result = await this.http.post('/users/partner_owner/inquiry', params)
    return result
  }

  /**
   * @returns {{
   *  data: import('data/types/booking').RestBooking[],
   *  meta: import('data/types/api').PaginationMeta & {
   *    booking_count: number
   *  }
   * }}
   */
  async getUserBookings (params) {
    const result = await this.http.get('/users/current/bookings', { params })
    return {
      data: result.data.data,
      meta: result.data.meta,
    }
  }

  async getUserSharedBookings () {
    const result = await this.http.get('/users/current/bookings/shared')
    return result.data
  }

  /**
   * Request an update to membership tier
   *
   * @param {Object} user The user to update
   * @param {Object} params
   * @param {string} params.tier The desired membership tier
   * @returns {any}
   */
  async updateMembership (user, params) {
    const result = await this.http.post(`/users/${user.id}/membership`, params)
    return result.data.data
  }

  /**
   * Invites a friend to join the app
   */
  async inviteFriend (data) {
    const result = await this.http.post('/users/invite-friend', data)
    return result.data.data
  }

  /**
   * Gets all of the current users shared users
   */
  async getSharedUsers (userId) {
    const result = await this.http.get(`/users/${userId}/shared-users`)
    return result.data
  }
}
