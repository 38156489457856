import DestinationSearchCombobox from 'components/modals/frontend/Destinations/Searchbar/DestinationSearchbar'
import PublicModal from 'components/PublicModal'
import { LoadingIcon } from 'components/icons'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import DestinationModalCountryList from './DestinationModalCountryList'
import DestinationModalItem from './DestinationModalItem'
import { useRouter } from 'next/router'
import { joinBy } from '@ama-selections/ui'
import DestinationModalContinentList from './DestinationModalContinentList'
import { useDestinationQuery } from 'data/hooks/Destinations/useDestinationQuery'
import DestinationModalViewPropertiesCard from './DestinationModalViewPropertiesCard'

const DestinationsModal = ({ isOpen, onClose }) => {
  const router = useRouter()

  useEffect(() => {
    onClose()
  }, [router.query?.country, router.query?.region, router.query?.subregion])

  const {
    continentId,
    setContinentId,
    countryId,
    setCountryId,
    dataType,
    data,
    fetching,
  } = useDestinationQuery(isOpen)

  useEffect(() => {
    if (isOpen) {
      setCountryId(null)
      setContinentId(undefined)
    }
  }, [isOpen])

  const handleDisplayDestinationItem = (columnIndex) => {
    switch (dataType) {
      case 'country':
        return data?.country?.regions?.data?.map((region, index) => (
          index % 4 === columnIndex
            ?            <DestinationModalItem
              key={`destination-region-${region?.id}`}
              parent={{
                ...region,
                slug: joinBy([
                  data?.country?.slug,
                  region?.slug,
                ], '/'),
              }}
              childLocationKey="subRegions"
            />
            : null
        ))
      case 'all':
      default:
        return data?.countries?.data?.map((country, index) => (
          index % 4 === columnIndex
            ? <DestinationModalItem
              key={`destination-country-${country?.id}`}
              parent={country}
              childLocationKey="regions.data"
            />
            : null
        ))
    }
  }

  useEffect(() => {
    if (countryId) {
      setCountryId(null)
    }
  }, [continentId])

  return (
    <>
      <PublicModal
        isOpen={isOpen}
        onClose={onClose}
        className="max-w-[1200px] max-h-[700px] md:h-full w-full"
        modalOverflow={null}
        innerPadding="!pt-30 !pb-0 !px-0"
        buttonType="hidden"
        overlayCloseButton={true}
      >
        <div className="flex flex-col h-full gap-y-50 max-h-[700px] overflow-auto">
          <div className="flex items-center justify-between w-full px-40">
            <h2 className="font-serif font-bold text-26 leading-title tracking-title text-grey-900">
              Destinations
            </h2>

            <DestinationSearchCombobox
              className="w-full max-w-[415px] z-10"
            />
          </div>

          <DestinationModalContinentList
            pause={!isOpen}
            continentId={continentId}
            setContinentId={setContinentId}
          />

          <div className="flex h-full min-h-0 px-40">
            <DestinationModalCountryList
              pause={!isOpen}
              countryId={countryId}
              setCountryId={setCountryId}
              continentId={continentId}
            />

            {
              fetching
                ? <div className="flex justify-center w-full pt-100">
                  <LoadingIcon />
                </div>
                : <>
                  <div className="gap-y-[20px] flex w-full overflow-y-auto text-14 leading-23 gap-x-10 px-20 mx-10">
                    <div className="flex flex-col basis-1/4">{handleDisplayDestinationItem(0)}</div>
                    <div className="flex flex-col basis-1/4">{handleDisplayDestinationItem(1)}</div>
                    <div className="flex flex-col basis-1/4">{handleDisplayDestinationItem(2)}</div>
                    <div className="flex flex-col basis-1/4">{handleDisplayDestinationItem(3)}</div>
                  </div>

                  <DestinationModalViewPropertiesCard
                    data={data}
                    dataType={dataType}
                  />
                </>
            }

          </div>
        </div>
      </PublicModal>
    </>
  )
}

DestinationsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export default DestinationsModal
