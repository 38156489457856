import { SurveyFormProps } from 'data/types/service_surveys'
import { useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import classNames from 'classnames'
import { ServiceSurvey } from 'data/enums/service-survey'

interface ServiceRequestSlideProps extends Pick<SurveyFormProps, 'position' | 'isSlideActive' | 'setValue' | 'clearErrors'> {
  children: JSX.Element | JSX.Element[]
  slideNumber: number
}

const ServiceRequestSlide = ({
  children,
  slideNumber,
  position,
  isSlideActive,
  setValue,
  clearErrors,
}: ServiceRequestSlideProps) => {
  const router = useRouter()

  const surveyType = useMemo(() => {
    return router.pathname.replace('/home/reservations/[id]/service-request/forms/', '')
  }, [router.pathname])

  const viewWidth = useMemo(() => {
    switch (surveyType) {
      case 'snowsports-instructor':
        return ([5, 6].includes(slideNumber))
          ? 'xl'
          : 'sm'
      case ServiceSurvey.PRE_STOCKING:
        return 'md'
      default:
        return 'sm'
    }
  }, [surveyType, slideNumber])

  useEffect(() => {
    if (isSlideActive) {
      setValue('slide_number', slideNumber)
      setValue('position', position)
    }
    clearErrors()
  }, [isSlideActive])

  return (
    <div className={classNames('flex flex-col lg:justify-center my-auto px-20 pt-20 pb-safe-offset-90 w-full gap-y-37 min-h-full', {
      '!max-w-[1200px]': viewWidth === 'xl',
      'max-w-[880px]': viewWidth === 'md',
      'max-w-[698px]': viewWidth === 'sm',
    })}>
      {children}
    </div>
  )
}

export default ServiceRequestSlide
