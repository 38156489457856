import { ServiceSurvey } from 'data/enums/service-survey'
import { TripScheduleService } from 'data/types/bookings/trip_schedule'
import { getAirportTransferServiceDetails } from 'data/helpers/survey/tripScheduleSurveys/airport-transfer'
import { getBoatCharterServiceDetails } from 'data/helpers/survey/tripScheduleSurveys/boat-charter'
import { getCarRentalServiceDetails } from 'data/helpers/survey/tripScheduleSurveys/car-rental'
import { getAtHomeCookingClassDetails } from 'data/helpers/survey/tripScheduleSurveys/at-home-cooking-class'
import { getPrivateChefServiceDetails } from 'data/helpers/survey/tripScheduleSurveys/private-chef'
import { getSkiInstructorServiceDetails } from 'data/helpers/survey/tripScheduleSurveys/ski-instructor'
import { getAtHomeSkiFittingServiceDetails } from 'data/helpers/survey/tripScheduleSurveys/at-home-ski-fitting'
import { getSkiPassesServiceDetails } from 'data/helpers/survey/tripScheduleSurveys/ski-passes'
import { parseTime } from 'data/helpers/time'
import { formatTimeFromTime } from 'data/helpers/dates'
import { joinBy } from '@ama-selections/ui'
import { TripScheduleServiceType } from 'gql/graphql'

const getServiceDetails = (service?: TripScheduleService) => {
  if (!service) {
    return undefined
  }

  switch (service.group?.service?.survey_slug) {
    case ServiceSurvey.PRIVATE_CHEF:
      return getPrivateChefServiceDetails(service)
    case ServiceSurvey.AIRPORT_TRANSFER:
      return getAirportTransferServiceDetails(service)
    case ServiceSurvey.BOAT_CHARTER:
    case ServiceSurvey.SAILBOAT_CHARTER:
      return getBoatCharterServiceDetails(service)
    case ServiceSurvey.CAR_RENTAL:
      return getCarRentalServiceDetails(service)
    case ServiceSurvey.AT_HOME_COOKING_CLASS:
    case ServiceSurvey.PIZZA_MAKING_CLASS:
      return getAtHomeCookingClassDetails(service)
    case ServiceSurvey.SKI_INSTRUCTOR:
    case ServiceSurvey.SNOWBOARD_INSTRUCTOR:
      return getSkiInstructorServiceDetails(service)
    case ServiceSurvey.SKI_PASSES:
      return getSkiPassesServiceDetails(service)
    case ServiceSurvey.AT_HOME_SKI_FITTING:
      return getAtHomeSkiFittingServiceDetails(service)
    default:
      return service.service_details ?? service.group?.service_details
  }
}

const getStartTime = (startTime?: string, formatFromTime = false) => {
  const formatFunction = formatFromTime ? formatTimeFromTime : parseTime
  return startTime ? formatFunction(startTime, true) : 'TBC'
}

const getFormattedTime = (
  startTime: string | null | undefined,
  endTime: string | null | undefined,
  type: TripScheduleServiceType,
) => {
  if (!startTime && !endTime) {
    if (type === TripScheduleServiceType.CustomEvent) {
      return 'All Day'
    } else {
      return 'TBC'
    }
  }

  if (startTime === endTime) {
    return formatTimeFromTime(startTime)
  }

  return joinBy(
    [
      formatTimeFromTime(startTime),
      formatTimeFromTime(endTime),
    ],
    ' - ',
  )
}

export {
  getServiceDetails,
  getStartTime,
  getFormattedTime,
}
