import React, { createRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { UploadIcon, UserCircleIcon } from 'components/icons'

const ProfileImageUpload = ({ className = '', value, onChange }) => {
  const fileRef = createRef()
  const [file, setFile] = useState()
  const [previewUrl, setPreviewUrl] = useState(value)

  const handleButtonClick = () => {
    fileRef.current.click()
  }

  useEffect(() => {
    if (!file) {return}
    onChange(file)
    setPreviewUrl(URL.createObjectURL(file))
    return () => {
      URL.revokeObjectURL(previewUrl)
    }
  }, [file])

  return (
    <div className={`relative w-[200px] h-[200px] ${className}`}>
      {
        previewUrl
          ? <img src={previewUrl} alt="" className="w-full h-full rounded-full p-30 object-cover" />
          : <UserCircleIcon className="w-full h-full rounded-full p-30" />
      }

      <button
        type="button"
        className="absolute bottom-0 right-0 bg-primary bg-opacity-4 hover:bg-opacity-10 rounded-full transition w-45 h-45 flex items-center justify-center focus:outline-none focus:ring ring-primary ring-opacity-50"
        onClick={handleButtonClick}
      >
        <UploadIcon />
      </button>

      <input type="file" accept="image/*" className="hidden" ref={fileRef} onChange={e => setFile(e.target.files[0])} />
    </div>
  )
}

ProfileImageUpload.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export default ProfileImageUpload
