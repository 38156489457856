import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import { NavSection, NavSectionProps } from 'components/user/profile/NavSection'
import { NavItem, NavItemProps } from 'components/user/profile/NavItem'
import {
  ChevronRightFlaredIcon,
  ProfileBellIcon,
  ProfileEmailIcon,
  ProfileFlagIcon,
  ProfileHomeIcon,
  ProfileInviteIcon,
  ProfileLogoutIcon,
  ProfilePageIcon,
  ProfilePhoneIcon,
  WhiteHeartIcon,
  ProfileShieldIcon,
  ProfileMembershipIcon,
} from 'components/icons'
import useAuth from 'data/hooks/User/useAuth'
import InviteFriendsModal from 'components/InviteFriendsModal'
import { useUnreadCount } from 'data/hooks/useUnreadCount'
import ApiClient from 'data/api/api_client'
import ProfileImage from 'components/ProfileImage'
import EditCustomerProfileModal from 'components/user/profile/modals/EditCustomerProfileModal'
import { Membership } from 'data/enums/membership-type'
import useNotificationPreferences, { NotificationPreferenceType } from 'data/hooks/User/useNotificationPreferences'
import { useMobileApp } from 'data/hooks/MobileApp/useMobileApp'
import CustomerGetInTouchModal from 'components/modals/getHelp/CustomerGetInTouchModal'
import Button from 'components/Button'
import { useDispatch } from 'react-redux'
import { setLoginModalOpen, setRegisterModalOpen } from 'store/auth'
import CustomerMembershipModal from 'components/user/profile/modals/CustomerMembershipModal'
import { MobileMessageActions } from 'data/helpers/mobile/messageChannels'
import MobileDestinationsModal from 'components/modals/home/destinations/MobileDestinationsModal'
import DestinationsModal from 'components/modals/frontend/Destinations/DestinationModal'
import { MobileScreenWidths, useScreenWidth } from 'data/hooks/useScreenWidth'
import { useRouter } from 'next/router'
import LoginAndSecurityModal from 'components/user/profile/modals/LoginAndSecurityModal'

interface ProfileModalNavigationProps {
  onClose: () => void
  onIsChildModalOpenChange?: (isOpen: boolean) => void
}

enum ProfileSubModal {
  EditModal,
  InviteFriends,
  GetHelp,
  Membership,
  SocialAccounts,
}

export const ProfileModalNavigation = ({
  onClose,
  onIsChildModalOpenChange,
}: ProfileModalNavigationProps) => {
  const { user, logout } = useAuth()
  const { isMobile } = useScreenWidth(MobileScreenWidths.LG)
  const { isUpdating: isEmailUpdating, toggle: toggleEmail } = useNotificationPreferences(NotificationPreferenceType.Email)
  const { isUpdating: isPushUpdating, toggle: togglePush } = useNotificationPreferences(NotificationPreferenceType.Push)
  const dispatch = useDispatch()
  const { pathname } = useRouter()

  const { isMobileApp } = useMobileApp()
  const { unreadNotificationCount } = useUnreadCount()

  const logoutUser = () => {
    // If the user is on mobile, fire the event to clear
    // cookies before invalidating the CSRF token
    if (isMobileApp && typeof AmaMobileApp !== 'undefined') {
      AmaMobileApp.postMessage(MobileMessageActions.LOGOUT)
    }

    ApiClient.auth.logout().then(() => {
      logout()
      onClose()
    })
  }

  const [modalState, setModalState] = useState<{
    option: ProfileSubModal
  } | undefined>(undefined)

  const [isDestinationsOpen, setIsDestinationsOpen] = useState(false)

  useEffect(() => {
    onIsChildModalOpenChange?.(!!modalState)
  }, [modalState?.option])

  const helpAndOtherSection: NavSection = {
    heading: 'Help & Other',
    items: [
      {
        children: 'Contact Us',
        icon: {
          child: <ProfilePhoneIcon className="text-[21px]" />,
          position: 'right',
        },
        onClick: () => setModalState({ option: ProfileSubModal.GetHelp }),
      },
      {
        children: 'About',
        icon: {
          child: <ProfilePageIcon className="-ml-2 text-24" />,
          position: 'right',
        },
        href: '/about-us',
      },
      {
        children: 'Terms of Service',
        icon: {
          child: <ProfilePageIcon className="-ml-2 text-24" />,
          position: 'right',
        },
        href: '/legal-notice',
      },
      {
        children: 'Privacy Policy',
        icon: {
          child: <ProfilePageIcon className="-ml-2 text-24" />,
          position: 'right',
        },
        href: '/privacy-policy',
      },
      ...(user
        ? [{
          children: 'Logout',
          icon: {
            child: <ProfileLogoutIcon className="text-[23px]" />,
            position: 'right' as const,
          },
          onClick: logoutUser,
        }]
        : []
      ),
    ],
  }

  const partnershipsSection: NavSection = {
    heading: 'Partnerships',
    items: [
      {
        children: 'List with Us',
        href: '/owners',
      },
      {
        children: 'Become a Travel Partner',
        href: '/travel-partners',
      },
    ],
  }

  const notificationSection: NavSection = {
    heading: 'System Notifications',
    items: [
      {
        children: 'Email',
        icon: {
          child: <ProfileEmailIcon className="text-22" />,
          position: 'left',
        },
        toggle: {
          checked: user?.via_email ?? false,
          onChange: toggleEmail,
          isLoading: isEmailUpdating,
          disabled: true,
        },
      },
      {
        children: 'Push',
        icon: {
          child: <ProfileFlagIcon className="text-22" />,
          position: 'left',
        },
        toggle: {
          checked: user?.via_push ?? false,
          onChange: togglePush,
          isLoading: isPushUpdating,
        },
      },
    ],
  }

  const destinationsSection: NavSection = {
    ...(user && { heading: 'Destinations' }),
    items: [
      {
        children: 'Destinations',
        onClick: () => {
          setIsDestinationsOpen(true)
          onIsChildModalOpenChange?.(true)
        },
      },
      {
        children: 'Services',
        href: '/services',
      },
      {
        children: 'Membership & Rewards',
        href: '/membership',
      },
    ],
  }

  const userSection: NavSection = {
    items: [
      {
        children: 'Notifications',
        icon: {
          child: <ProfileBellIcon className="text-22" />,
          position: 'left',
        },
        href: '/home/notifications',
        number: unreadNotificationCount ?? undefined,
      },
      {
        children: 'Dashboard',
        icon: {
          child: <ProfileHomeIcon className="text-[21px] stroke-[1.5]" />,
          position: 'left',
        },
        href: '/home',
      },
      {
        children: 'Wishlists',
        icon: {
          child: <WhiteHeartIcon className="text-22 stroke-[1.5]" />,
          position: 'left',
        },
        href: '/home/wishlists',
      },
      {
        children: 'Magazine',
        icon: {
          child: <ProfilePageIcon className="-ml-2 text-24" />,
          position: 'left',
        },
        href: '/magazine',
      },
    ],
  }

  const membershipSection: NavSection = {
    heading: 'Account & Membership',
    items: [
      {
        children: <>
          Membership Status: <span className="ml-5 capitalize text-primary-fresh">{user?.membership_type ?? Membership.REGISTERED}</span>
        </>,
        icon: {
          child: <ProfileMembershipIcon className="text-22 stroke-[1.5] ml-4 -mr-4" />,
          position: 'left',
        },
        onClick: () => setModalState({ option: ProfileSubModal.Membership }),
      },
      {
        children: 'Invite friends & Earn travel credit',
        icon: {
          child: <ProfileInviteIcon className="text-[25px] -mr-2" />,
          position: 'left',
        },
        onClick: () => setModalState({ option: ProfileSubModal.InviteFriends }),
      },
      {
        children: 'Login & Security',
        icon: {
          child: <ProfileShieldIcon className="ml-4 -mr-4 text-[21px]" />,
          position: 'left',
        },
        onClick: () => setModalState({ option: ProfileSubModal.SocialAccounts }),
      },
    ],
  }

  type NavSection = {
    heading?: NavSectionProps['heading']
    items: NavItemProps[]
  }

  const NavItems: NavSection[] = [
    userSection,
    membershipSection,
    ...(!isMobileApp
      ? [{
        heading: 'General',
        items: destinationsSection.items,
      }]
      : []
    ),
    partnershipsSection,
    ...(isMobileApp ? [notificationSection] : []),
    helpAndOtherSection,
  ]

  const UnAuthenticatedNavItems: {
    heading?: NavSectionProps['heading']
    items: NavItemProps[]
  }[] = [
    ...(!isMobileApp ? [destinationsSection] : []),
    {
      items: [
        {
          children: 'Magazine',
          href: '/magazine',
          icon: {
            child: <ProfilePageIcon className="-ml-2 text-24" />,
            position: 'right',
          },
        },
      ],
    },
    partnershipsSection,
    helpAndOtherSection,
  ]

  const renderableNavItems = useMemo(
    () => user ? NavItems : UnAuthenticatedNavItems,
    [user, NavItems],
  )

  return (
    <>
      {user
        ? (
          <>
            <button
              type="button"
              onClick={() => setModalState({ option: ProfileSubModal.EditModal })}
              className={classNames(
                'flex items-center mx-10 px-12 text-left mt-26 py-8 mb-8 text-grey-500',
                'focus:outline-none focus:text-primary-fresh',
                'hover:bg-grey-100 rounded-16',
              )}
              sr-only="Show profile"
            >
              <ProfileImage
                user={user}
                className="min-w-[40px] max-w-[40px] min-h-[40px] max-h-[40px]"
              />

              <div className="flex flex-col w-full ml-14 tracking-1/2">
                <h3 className="text-blue-1000 text-[17px] leading-24">
                  {user?.first_name ?? ''}
                </h3>

                <p className="text-12 leading-18">
                  Show profile
                </p>
              </div>

              <ChevronRightFlaredIcon className="stroke-2 text-24" />
            </button>
          </>
        )
        : null
      }

      {renderableNavItems.map(({ items, heading }, index) => (
        <>
          {
            user
              ? <Divider
                key={`${index}-${heading}-pre-divider`}
                className={classNames('!mb-12', {
                  'hidden': index !== 0,
                })
                }
              />
              : null
          }
          <NavSection
            key={`${index}-${heading}-section`}
            heading={heading}
          >
            {
              items.map(({ children, ...itemProps }, index) => {
                const props = itemProps

                if (props.href && props.href === pathname) {
                  delete props.href
                  props.onClick = () => onClose()
                }

                return (
                  <NavItem
                    key={`${index}-${heading}-item-${index}`}
                    className={classNames({
                      'last:mb-14': items.length > 1,
                    })}
                    {...props}
                  >
                    {children}
                  </NavItem>
                )
              })}
          </NavSection>
          <Divider
            key={`${index}-${heading}-post-divider`}
            className={classNames({
              'hidden': index === renderableNavItems.length - 1,
            })}
          />
        </>
      ))}

      {!user
        ? <div className="sticky bottom-0 flex flex-col pt-10 mt-auto bg-white pb-safe-offset-10">
          <Button
            onClick={() => dispatch(setLoginModalOpen(true))}
            className="mt-auto mb-2 mx-20 !rounded-16"
            variant="primary"
          >
            Sign in
          </Button>
          <div className="mt-10 text-center text-14 text-grey-500">
            Don&apos;t have an account yet? <span className="underline text-primary" onClick={() => dispatch(setRegisterModalOpen(true))}>Sign up now</span>
          </div>
        </div>
        : null
      }

      <EditCustomerProfileModal
        isOpen={modalState?.option === ProfileSubModal.EditModal}
        onClose={() => setModalState(undefined)}
      />

      <InviteFriendsModal
        isOpen={modalState?.option === ProfileSubModal.InviteFriends}
        onClose={() => setModalState(undefined)}
      />

      <CustomerGetInTouchModal
        isOpen={modalState?.option === ProfileSubModal.GetHelp}
        onClose={() => setModalState(undefined)}
      />

      <CustomerMembershipModal
        isOpen={modalState?.option === ProfileSubModal.Membership}
        onClose={() => setModalState(undefined)}
      />

      <LoginAndSecurityModal
        isOpen={modalState?.option === ProfileSubModal.SocialAccounts}
        onClose={() => setModalState(undefined)}
      />

      <DestinationsModal
        isOpen={isDestinationsOpen && !isMobile}
        onClose={() => {
          setIsDestinationsOpen(false)
          onIsChildModalOpenChange?.(false)
        }}
      />

      <MobileDestinationsModal
        redirect={true}
        isOpen={isDestinationsOpen && isMobile}
        onClose={() => {
          setIsDestinationsOpen(false)
          onIsChildModalOpenChange?.(false)
        }}
      />
    </ >
  )
}

interface DividerProps {
  className?: string
}

export const Divider = ({ className }: DividerProps) => (
  <hr className={classNames(
    'mx-20 my-4 text-grey-250',
    className,
  )} />
)
