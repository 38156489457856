import Dinero from 'dinero.js'

const CurrencyType = {
  Eur: 'EUR',
  Usd: 'USD',
  Chf: 'CHF',
}

const getLanguage = (currency) => {
  switch (currency?.toUpperCase()) {
    case CurrencyType.Eur:
    case CurrencyType.Chf:
    default:
      return 'fr-FR'
    case CurrencyType.Usd:
      return 'en-US'
  }
}

const getCurrencies = () => {
  return Object.values(CurrencyType)
}

const getCurrencySign = (currency = CurrencyType.Eur) => {
  const currencySign = {
    [CurrencyType.Eur]: '€',
    [CurrencyType.Usd]: '$',
    [CurrencyType.Chf]: '₣',
  }

  return currencySign[currency]

}

const formatCurrency = (value, currency = 'EUR') => {
  if (value !== 0 && !value) { return }

  return Dinero({ amount: parseInt(value), currency: currency }).setLocale(getLanguage(currency)).toFormat('$0,0.00')
}

/**
 * @param {string | CurrencyType | null | undefined} currency
 * @returns {string}
 */
const formatShortCurrency = (value, currency = 'EUR') => {
  if (value !== 0 && !value) { return }

  const hasSubUtils = Dinero({ amount: value }).hasSubUnits()

  return Dinero({ amount: parseInt(value), currency: currency })
    .setLocale(getLanguage(currency))
    .toFormat(hasSubUtils ? '$0,0.00' : '$0,0')
}

/**
 * @param {string | CurrencyType | null | undefined} currency
 * @returns {string}
 */
const formatShortCurrencyWithoutSpace = (value, currency = 'EUR') => {
  return formatShortCurrency(value, currency)?.replace(/\s+(?=\S*$)/, '')
}

const formatCurrencyExport = (value, currency = 'EUR') => {
  if (currency !== 'JPY') {
    value *= 100
  }
  return value
}

export {
  formatCurrency,
  formatShortCurrency,
  formatCurrencyExport,
  getCurrencySign,
  getCurrencies,
  formatShortCurrencyWithoutSpace,
}
