import { TripScheduleService } from 'data/types/bookings/trip_schedule'

const getAtHomeSkiFittingServiceDetails = (service: TripScheduleService) => {
  const serviceDetails = service.service_details ?? service.group?.service_details

  if (serviceDetails?.includes('$generate_details') && service.survey?.__typename === 'SurveySkiInstructorInfo') {
    const survey = service.survey

    if (survey?.guests?.length) {
      return serviceDetails.replaceAll(
        '$generate_details',
        `Ski fitting for ${survey.guests.length} guests. The service provider will bring different options and sizes for you to choose from.`,
      )
    }
  }

  return serviceDetails
}

export { getAtHomeSkiFittingServiceDetails }
