import { joinBy } from '@ama-selections/ui'
import { TripScheduleService } from 'data/types/bookings/trip_schedule'
import { PassTypeEnum } from 'data/enums/survey/ski-instructor/pass-type'

const getSkiPassesServiceDetails = (service: TripScheduleService) => {
  const serviceDetails = service.service_details ?? service.group?.service_details

  if (serviceDetails?.includes('$generate_details') && service.survey?.__typename === 'SurveySkiInstructorInfo') {
    const survey = service.survey

    const skiPassesCount = survey?.guests?.filter((guest) => guest.pass_type === PassTypeEnum.SkiPass)?.length
    const pedestrianPassesCount = survey?.guests?.filter((guest) => guest.pass_type === PassTypeEnum.PedestrianPass)?.length

    if (skiPassesCount || pedestrianPassesCount) {
      const skiPassesText = skiPassesCount ? `${skiPassesCount} ski passes` : ''
      const pedestrianPassesText = pedestrianPassesCount ? `${pedestrianPassesCount} pedestrian passes` : ''

      return serviceDetails.replaceAll(
        '$generate_details',
        joinBy(
          [
            skiPassesText,
            pedestrianPassesText,
          ],
          ' and ',
        ),
      )
    }
  }

  return serviceDetails
}

export { getSkiPassesServiceDetails }
