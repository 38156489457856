const slugify = (string?: string) => {
  return string?.replace(/[^a-zA-Z0-9 -]/g, '').replace(/\s+/g, '-').replace(/-+/g, '-').toLowerCase()
}

const singularise = (string: string) => {
  if (string.charAt(string.length - 1) === 's') {
    return string.substring(0, string.length - 1)
  } else {
    return string
  }
}

const pluralise = (
  count: number = 0,
  base: string,
  pluralEnding: string = 's',
  singularEnding: string = '',
  withNumber: boolean = false,
) => {
  return (withNumber ? `${count} ` : '')
    + (count === 1
      ? base + singularEnding
      : base + pluralEnding)
}

const pluraliseWithNumber = (
  count: number | null = 0,
  base: string | null,
  options: {
    pluralEnding?: string
    singularEnding?: string
    nullable?: boolean
    showPlaceholderOnNull?: boolean
  } = {
    pluralEnding: 's',
    singularEnding: '',
    nullable: false,
    showPlaceholderOnNull: false,
  },
) => {
  if (count === null || base === null) {
    return null
  }

  if (count === 0) {
    if (options?.showPlaceholderOnNull) {
      return base + (options?.pluralEnding ?? 's')
    }

    if (options?.nullable) {
      return null
    }
  }

  return pluralise(count, base, options?.pluralEnding, options?.singularEnding, true)
}

const capitaliseFirstLetter = (string: string) => {
  return typeof string === 'string'
    ? string[0]?.toUpperCase() + string.substring(1)
    : string
}

const validateBoolean = (boolean: string | boolean | number) => {
  return (boolean === 'true' || boolean === true || boolean === 1 || boolean === '1')
}

const formatEnum = (value: string) => {
  return value
    ? value
      .toLowerCase()
      .replaceAll('_', ' ')
      .split(' ')
      .map(word => capitaliseFirstLetter(word))
      .join(' ')
    : value

}

const joinBy = (
  array: (number | string | null | undefined)[] = [],
  separator: string = ', ',
  options: {
    withPrefix?: boolean
    withSuffix?: boolean
  } = {
    withPrefix: false,
    withSuffix: false,
  },
) => {
  const joinedValue = array.filter(v => v).join(separator)

  if (options?.withPrefix || options?.withSuffix) {
    return `${options?.withPrefix ? separator : ''}${joinedValue}${options?.withSuffix ? separator : ''}`
  } else {
    return joinedValue
  }
}

const asInteger = (value: string | number | null | undefined): number | null => {
  if (value === null || value === undefined) {
    return null
  }

  if (typeof value === 'string') {
    return parseInt(value)
  }

  return value
}

const getOrdinalAdverb = (number: number) => {
  switch (number) {
    case 1:
      return 'first'
    case 2:
      return 'second'
    case 3:
      return 'third'
    default:
      return null
  }
}

export {
  slugify,
  singularise,
  pluralise,
  pluraliseWithNumber,
  capitaliseFirstLetter,
  validateBoolean,
  formatEnum,
  joinBy,
  getOrdinalAdverb,
  asInteger,
}
