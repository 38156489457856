import React, { Fragment, useMemo } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { CrossIcon } from 'components/icons'
import { ReactChild } from 'data/types/types'
import { ReactTag } from '@headlessui/react/dist/types'

interface DashboardModalPropTypes {
  className?: string
  title: string | ReactChild
  size?: 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '6xl' | '800' | '1200'
  isOpen: boolean
  onClose: () => void
  closeOnLayoutClick?: boolean
  isStatic?: boolean
  children: ReactChild
}

const DashboardModal = ({
  className,
  isOpen,
  onClose,
  children,
  title,
  size = 'md',
  closeOnLayoutClick = true,
  isStatic,
}: DashboardModalPropTypes) => {
  const sizeClass = useMemo(
    () => {
      switch (size) {
        case 'md':
          return 'max-w-md'
        case 'lg':
          return 'max-w-lg'
        case 'xl':
          return 'max-w-xl'
        case '2xl':
          return 'max-w-2xl'
        case '3xl':
          return 'max-w-3xl'
        case '4xl':
          return 'max-w-4xl'
        case '6xl':
          return 'max-w-6xl'
        case '800':
          return 'max-w-[800px]'
        case '1200':
          return 'max-w-[1200px]'
        default:
          return 'max-w-md'
      }
    },
    [size],
  )

  return (
    <Transition appear show={isOpen} as={Fragment as ReactTag}>
      <Dialog
        static={typeof isStatic === 'boolean' ? isStatic : undefined}
        open={ typeof isStatic === 'boolean' ? isOpen : undefined}
        className="relative z-[60]"
        onClose={closeOnLayoutClick ? onClose : () => {}}
      >
        <Transition.Child
          as={Fragment as ReactTag}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-10" />
        </Transition.Child>

        {/* This element is to trick the browser into centering the modal contents. */}
        <div className="fixed inset-0 flex items-end lg:items-center justify-center z-[59]">

          <Transition.Child
            as={Fragment as ReactTag}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-[100%] lg:translate-y-0 lg:scale-95"
            enterTo="opacity-100 translate-y-0  lg:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 lg:scale-100"
            leaveTo="opacity-0 translate-y-[100%] lg:translate-y-0 lg:scale-95"
          >
            <Dialog.Panel className={classNames('w-full max-h-[85vh] h-full md:h-min mx-auto relative overflow-y-auto text-left align-middle bg-white rounded-t-20 lg:rounded-20 shadow-modal', sizeClass, className)}>
              <Dialog.Title as="div" className="flex flex-col px-20 font-medium border border-none bg-primary-fresh lg:bg-primary lg:bg-opacity-4 bg-opacity-6 p-25 lg:border-primary border-opacity-30 rounded-t-20 lg:flex-row-reverse lg:items-center lg:justify-between lg:font-normal">
                <button
                  type="button"
                  className="mb-20 ml-auto lg:bg-white lg:ml-0 lg:mb-0 lg:p-15 text-18 rounded-4 lg:rounded-8 lg:shadow-modal-close focus:outline-none lg:focus:ring ring-primary ring-opacity-30"
                  onClick={onClose}
                >
                  <CrossIcon />
                </button>
                {
                  typeof title === 'string'
                    ? <h3 className="text-16 lg:text-18 text-primary lg:w-3/4 lg:px-20">{title}</h3>
                    : title
                }
              </Dialog.Title>
              <div className="p-20">
                {children}
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default DashboardModal
