import PropTypes from 'prop-types'
import { gql, useQuery } from 'urql'
import classNames from 'classnames'

const DestinationModalCountryList = ({
  pause,
  countryId,
  setCountryId,
  continentId,
}) => {
  const [{ data }] = useQuery({
    query: gql`
      query DestinationModalCountryList (
        $orderBy: [OrderByClause!],
        $filters: CountriesFilterInput,
      ) {
        countries (first: 100, orderBy: $orderBy, filters: $filters) {
          data {
            id
            value
          }
        }
      }
    `,
    variables: {
      orderBy: [{
        column: 'VALUE',
        order: 'ASC',
      }],
      filters: continentId
        ? { continent_id: continentId }
        : null,
    },
    pause: pause && typeof window !== 'undefined',
  })

  return (
    <div className="flex flex-col gap-25 overflow-y-auto max-w-[150px] w-full border-r border-grey-250 mb-10 pr-10">
      {
        data && data?.countries?.data?.map((country) => (
          <button
            key={`country-button-${country.id}`}
            onClick={() => setCountryId(countryId === country?.id ? null : country?.id)}
            className={classNames(
              'text-14 max-w-max leading-20 tracking-1/2 cursor-pointer font-bold text-grey-800 uppercase text-left',
              {
                'underline decoration-primary decoration-2 underline-offset-2': countryId === country?.id,
                'border-transparent': countryId !== country?.id,
              },
            )}
          >
            {country.value}
          </button>
        ))
      }
    </div>
  )
}

DestinationModalCountryList.propTypes = {
  pause: PropTypes.bool,
  countryId: PropTypes.string,
  setCountryId: PropTypes.func,
  continentId: PropTypes.string,
}

export default DestinationModalCountryList
