import { createSlice } from '@reduxjs/toolkit'
import { DepositDeductionTypeEnum } from 'data/enums/deposit-deduction-type'

export const bookingPaymentDeductionsSlice = createSlice({
  name: 'bookingPaymentDeductions',
  initialState: {
    deductions: [],
    deposit_refunded_at: null,
    is_deposit_refunded: false,
    ready_for_refund_at: null,
    is_ready_for_refund: false,
  },
  reducers: {
    initialiseDeductions: (state, action) => {
      state.deductions = action.payload.deductions?.map((item) => {
        if (item.amount > 0) {
          return {
            ...item,
            type: DepositDeductionTypeEnum.REFUND,
          }
        }

        return {
          ...item,
          type: DepositDeductionTypeEnum.DEDUCTION,
          amount: Math.abs(item.amount),
        }
      })
    },
    updateDeductions: (state, action) => {
      state.deductions = JSON.parse(JSON.stringify(action.payload))
    },
    updateDeduction: (state, action) => {
      state.deductions[state.deductions?.findIndex(deduction => deduction?.id === action.payload?.id)] = action.payload
    },
    setDepositRefundedAt: (state, action) => {
      state.deposit_refunded_at = action.payload
    },
    setIsDepositRefunded: (state, action) => {
      state.is_deposit_refunded = action.payload
    },
    setReadyForRefundedAt: (state, action) => {
      state.ready_for_refund_at = action.payload
    },
    setIsReadyForRefunded: (state, action) => {
      state.is_ready_for_refund = action.payload
    },
  },
})

export const {
  initialiseDeductions,
  updateDeductions,
  updateDeduction,
  setIsDepositRefunded,
  setDepositRefundedAt,
  setReadyForRefundedAt,
  setIsReadyForRefunded,
} = bookingPaymentDeductionsSlice.actions

export default bookingPaymentDeductionsSlice.reducer
