import { CalendarSearchBarIcon } from 'components/icons'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import Label from 'components/form/Label'
import Error from '../Error'
import { FieldError } from 'react-hook-form'
import { DayPickerInputProps } from 'react-day-picker'
import classNames from 'classnames'

interface DatePickerProps extends DayPickerInputProps {
  label?: string
  error?: FieldError
  variant?: 'thin' | 'thick'
}

const DatePicker = ({
  label,
  error,
  variant = 'thick',
  ...props
}: DatePickerProps) => {
  return (
    <div className="flex flex-col md:ml-40">
      {label && <Label variant="survey">{label}</Label>}

      <div className={classNames(
        'relative border rounded-16 DayPickerHome',
        {
          'border-red border-opacity-50': error,
          'border-grey-100': !error,
          '[&>.DayPickerInput>input]:!py-10 [&>.DayPickerInput>input]:!text-14 [&>.DayPickerInput>input]:!h-auto': variant === 'thin',
        },
      )}>
        <DayPickerInput
          {...props}
          dayPickerProps={{
            numberOfMonths: 1,
            weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            ...props.dayPickerProps,
          }}
          inputProps={{
            readOnly: true,
            ...props.inputProps,
          }}
        />

        <CalendarSearchBarIcon className="absolute top-0 bottom-0 justify-center my-auto pointer-events-none text-primary right-20 text-22" />
      </div>

      <Error className="mt-10" error={error?.message} />
    </div>
  )
}

export default DatePicker
