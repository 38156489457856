enum ServiceSurvey {
  PRIVATE_CHEF = 'private-chef',
  PRE_STOCKING = 'pre-stocking',
  AIRPORT_TRANSFER = 'airport-transfer',
  BOAT_CHARTER = 'boat-charter',
  SAILBOAT_CHARTER = 'sailboat-charter',
  CAR_RENTAL = 'car-rental',
  AT_HOME_COOKING_CLASS = 'at-home-cooking-class',
  PIZZA_MAKING_CLASS = 'pizza-making-class',
  SKI_PASSES = 'ski-passes',
  SKI_INSTRUCTOR = 'ski-instructor',
  AT_HOME_SKI_FITTING = 'at-home-ski-fitting',
  SNOWBOARD_INSTRUCTOR = 'snowboard-instructor'
}

const SERVICES_WITH_PDF = [
  ServiceSurvey.PRE_STOCKING,
  ServiceSurvey.SKI_INSTRUCTOR,
  ServiceSurvey.SNOWBOARD_INSTRUCTOR,
  ServiceSurvey.SKI_PASSES,
  ServiceSurvey.AT_HOME_SKI_FITTING,
]

export { ServiceSurvey, SERVICES_WITH_PDF }
